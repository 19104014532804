import { createSlice } from '@reduxjs/toolkit';
// import sum from 'lodash/sum';
// import uniqBy from 'lodash/uniqBy';
// utils
// import { produce } from 'immer';
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  exams: null,
  exam: null,
  examOverAllResult: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: '',
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
  },
};

const slice = createSlice({
  name: 'exams',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getExamsSuccess(state, action) {
      state.isLoading = false;
      state.exams = action.payload;
    },

    getExamSuccess(state, action) {
      state.isLoading = false;
      state.exam = action.payload;
    },

    getExamOverAllResultSuccess(state, action) {
      state.isLoading = false;
      state.examOverAllResult = action.payload;
    },

    getExamQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.examQuestions = action.payload;
    },

    getAllQuestionsSuccess(state, action) {
      state.isLoading = false;
      state.questions = action.payload;
    },

    getExamReviewSuccess(state, action) {
      state.isLoading = false;
      state.examQuestions = action.payload;
    },

    getSubmitResponse(state, action) {
      state.isLoading = false;
      state.submitResponse = action.payload;
    },

    getSaveResponse(state, action) {
      state.isLoading = false;
      state.saveResponse = action.payload;
    },

    updateSelectedOptions(state, action) {

      state.isLoading = false;
      state.examQuestions = action.payload;
      console.log('here', state.exams, action);
    },

    resetError(state) {
      state.error = null;
    },

    resetExamState(state){
      state.error = null;
      state.exam = null;
      state.exams = null;
      state.examQuestions = null;
      state.examOverAllResult = null;
    }


  },
});

// Reducer
export default slice.reducer;

// Actions
export const {
  resetError,
  resetExamState,

  updateSelectedOptions,
} = slice.actions;

// ----------------------------------------------------------------------

export function getExams() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/student/online-exam/all?limit=500', { // todo add paging
      });
      dispatch(slice.actions.getExamsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getExam(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/student/online-exam/${id}`, {});
      // const response = await axios.get(`api/online-exam/${id}`, {});
      console.log('Get exam', response);
      dispatch(slice.actions.getExamSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExamQuestions(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/student/online-exam/exam/${id}`, {});
      console.log(response);
      dispatch(slice.actions.getExamQuestionsSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllQuestions(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`api/question/all/${id}`, {});
      console.log(response);
      dispatch(slice.actions.getAllQuestionsSuccess(response.data.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function submitExam(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`api/student/online-exam/exam`, data);
      console.log('submitExam', response);
      dispatch(slice.actions.getSubmitResponse(response.data.message));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function saveAndNextExam(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`api/student/online-exam/exam`, data);
      dispatch(slice.actions.getSaveResponse(response.data.message));
    } catch (error) {
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export function clearQuestion(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post(`/api/student/online-exam/examresponse/clear`, data);
      console.log('clear Response', response);
    } catch (error) {
      console.error('submitExam', error);
      // dispatch(slice.actions.hasError(error));
    }
  };
}


export function getOverAllExamResult(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/student/online-exam/overall/result/${id}`, {});
      console.log('Get over all result', response);
      dispatch(slice.actions.getExamOverAllResultSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExamResult(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/student/online-exam/questionwise/result/${id}`, {});
      console.log('Get question wise result', response);
      dispatch(slice.actions.getExamQuestionsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getExamReview(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/api/student/online-exam/review/${id}`, {});
      dispatch(slice.actions.getExamReviewSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

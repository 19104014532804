import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';

export default function CommonDialog(props) {
  const [open, setOpen] = React.useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {props.dialogData.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {props.dialogData.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
           <Button onClick={handleClose}>{props.dialogData.secondaryButtonName}</Button>
          <Button onClick={handleClose} autoFocus>
            {props.dialogData.primaryButtonName}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

CommonDialog.propTypes = {
  dialogData: PropTypes.any.isRequired
}
